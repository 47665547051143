/**
 * Adds an opacity value to a CSS color.
 * @param {string} color CSS color code formatted as `#rgb`, `#rrggbb` or `rgb(red,green,blue)`
 * @param {string | number} opacity The opacity value to add to the original color
 * @returns {string} The original color in `rgba(red,green,blue,alpha)`
 */
export function alpha(color, opacity = 0) {
    const regex = /#(([0-9a-fA-F]{1,2})([0-9a-fA-F]{1,2})([0-9a-fA-F]{1,2}))|(rgb\((\d{1,3},\d{1,3},\d{1,3})\))/;
    const colorWithoutSpaces = color.replace(/\s/g, '');
    const parsedCSSColor = regex.exec(colorWithoutSpaces);
    let str = parsedCSSColor[6];
    if (!parsedCSSColor[6]) {
        const multiplier = parsedCSSColor[1].length === 3 ? 0x11 : 1;
        str = (
            parsedCSSColor
                .slice(2, 5)
                .map((channelValue) => parseInt(channelValue, 16) * multiplier)
                .join(',')
        );
    }
    return `rgba(${str},${opacity})`;
}

export default alpha;
