export const accent0 = '#00A83E';
export const accent1= '#f05921';
export const accent2 = '#33b2c8';
export const accent4 = '#fbb03b';

export const focus = '#0099ff';

export const gray0 = '#212226';
export const gray1 = '#30333c';
export const gray2 = '#40414a';
export const gray3 = '#4f515c';
export const gray4 = '#5e6063';
export const gray5 = '#7f8194';
export const gray6 = '#a6a7b5';
export const gray7 = '#cccdd6';
export const gray8 = '#f4f4f6';
export const gray9 = '#f5f5f7';

export const calendarBlue = '#54c0e8';
export const calendarBlueHover = '#cbecf8';
