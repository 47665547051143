import { staticUrl } from 'emerald-ui/dist/globals/urls';

export const forwarderConfig = {
    Centrade: {
        faviconUrl: staticUrl('images/centrade-favicon.svg'),
        logoSrc: staticUrl('centrade-horizontal-white.svg'),
    },
    Navegate: {
        faviconUrl: staticUrl('images/navegate.ico'),
        logoSrc: staticUrl('images/navegate-radiant-dark.svg'),
    }
};
